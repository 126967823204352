import request from '@index/utils/request'

export function handlePublicMatchInfo(data) {
  return request({
    url: 'matchinfo/publicMatchInfo',
    method: 'post',
    data
  })
}


export function handleMatchInfoDetails(id) {
  return request({
    url: 'matchinfo/details',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleQueryMatchRegulationsByMatchId(matchId) {// 获取赛事规程
  return request({
    url: 'matchinfo/queryMatchRegulationsByMatchId',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}